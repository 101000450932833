<template>
	<el-dialog class="z-dialog" title="添加评分规则" :visible.sync="isShow" width="750px" :close-on-click-modal="false">
		<el-form ref="form" :model="form" :rules="rules" label-width="110px">
			<el-form-item prop="name" label="评分规则名称">
				<el-input v-model="form.name" placeholder="请输入" style="width: 324px;" maxlength="50" />
			</el-form-item>

			<el-form-item prop="value" label="适用评选分类">
				<el-select v-model="form.value" multiple collapse-tags placeholder="请选择" style="width: 324px;">
					<el-option v-for="item in options" :key="item.channelId" :label="item.groupName + ':'+ item.channelName" :value="item.channelId"></el-option>
				</el-select>
			</el-form-item>

			<el-form-item label="评分项" required>
				<el-row class="margin-bottom__medium" :gutter="10" v-for="(item, index) in form.classifyList" :key="index">
					<el-col :span="8">
						<el-form-item :prop="'classifyList.' + index +'.itemName'" :rules="{ required: true, message: '请输入评分项名称', trigger: 'blur' }">
							<el-input v-model="item.itemName" placeholder="评分项名称" maxlength="50" />
						</el-form-item>
					</el-col>
					
					<el-col :span="3">
						<el-form-item :prop="'classifyList.' + index +'.score'" :rules="[{required: true, message: '请输入分值', trigger: 'blur' },{ type: 'number', message: '分值必须为数字值'}]">
							<el-input v-model.number="item.score" placeholder="分值" :min="1" :max="100" maxlength="3" />
						</el-form-item>
					</el-col>

					<el-col :span="10">
						<el-form-item :prop="'classifyList.' + index +'.itemDesc'" :rules="{ required: true, message: '请输入评分项名描述', trigger: 'blur' }">
							<el-input class="system-textarea" type="textarea" v-model="item.itemDesc" placeholder="评分项描述" rows="1" maxlength="500"  />
						</el-form-item>
					</el-col>
					
					<el-col :span="3">
						<el-button type="text" icon="el-icon-plus" @click="handleAdd(index)"></el-button>
						<el-button type="text" icon="el-icon-minus" v-if="index > 0" @click="handleRemove(index)"></el-button>
					</el-col>
				</el-row>
			</el-form-item>

			<el-form-item class="text-right">
				<el-button @click="quxiao">取消</el-button>
				<el-button type="primary" @click="handleSubmit">确定</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
	export default {
		data() {
			return {
				form: {
					id: "",
					name: "",
					value: [],
					classifyList: []
				},
				rules: {
					name: {
						required: true,
						message: "请输入评分规则名称",
						trigger: "blur"
					},
					value: {
						required: true,
						message: "请选择评选分类",
						trigger: "change"
					}
				},
				options: []
			};
		},

		props: {
			show: {
				type: Boolean,
				default: false
			},
			data: {}
		},

		computed: {
			isShow: {
				get() {
					return this.show;
				},
				set(value) {
					this.$emit("update:show", value);
				}
			}
		},

		mounted() {
			this.init();
		},

		methods: {
			init() {
//				console.log('data.id=',this.data.id);
				let temp = this;
				
				if(!this.form.classifyList.length) {
					this.form.classifyList.push(this.getClassifyItem());
				}
				
				//查询分类
				this.$post('/findChannelList', {}).then(rep => {
	      	this.options = rep.content.list;
	      })
				
				if(this.data.id){
					this.$post('/findRule', {
						ruleId: this.data.id
		      }).then(rep => {
		      	let tRatingRule = rep.content.tRatingRule;
		      	temp.form.id = tRatingRule.id;
		      	temp.form.name = tRatingRule.ratingName;
		      	temp.form.value = tRatingRule.channels;
		      	temp.form.classifyList = tRatingRule.itemList;
		      })
				}
				
			},

			handleSubmit() {
				let temp = this;
				this.$refs["form"].validate(valid => {
					if(valid) {
//						console.log(JSON.stringify(this.form));
						this.$post('/addRule', {
							form: JSON.stringify(this.form)
			      }).then(rep => {
			      	temp.$message({
								type: 'success',
								message: '添加成功！'
							});
							temp.isShow = false;
							//刷新父页面
							temp.$emit('init');
			      })
						
					} else {
						return false;
					}
				});
			},

			getClassifyItem() {
				const _classifyAddItem = {
					id: "",
					itemName: "",
					score: ""
				};
				return _classifyAddItem;
			},

			handleAdd(index) {
				this.form.classifyList.splice(index + 1, 0, this.getClassifyItem());
			},

			handleRemove(index) {
				this.form.classifyList.splice(index, 1);
			},
			
			quxiao(){
					this.isShow = false;
			}
		}
	};
</script>

<style lang="scss" scoped>
	/deep/ .el-form>.el-form-item>.el-form-item__content {
		padding: 0 5px;
	}
	
	.system-textarea /deep/ .el-textarea__inner {
		min-height: 36px!important;
	}
</style>