<template>
	<el-dialog class="z-dialog" title="添加组别" :visible.sync="isShow" width="670px" :close-on-click-modal="false">
		<el-form ref="form" :model="form" :rules="rules" label-width="80px">
			<el-form-item prop="name" label="组别名称">
				<el-input v-model="form.name" placeholder="请输入" style="width: 324px;" maxlength="50" />
			</el-form-item>

			<el-form-item label="分类" required>
				<el-row class="margin-bottom__medium" :gutter="10" v-for="(item, index) in form.classifyList" :key="index">
					<el-col :span="6">
						<el-form-item :prop="'classifyList.' + index +'.channelName'" :rules="{ required: true, message: '请输入分类名称', trigger: 'blur' }">
							<el-input v-model="item.channelName" placeholder="请输入分类名称" maxlength="50" />
						</el-form-item>
					</el-col>

					<el-col :span="5">
						<el-form-item :prop="'classifyList.' + index +'.type'" :rules="{required: true, message: '请选择分类', trigger: 'change'}">
							<el-select v-model="item.type" placeholder="请选择">
								<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
							</el-select>
						</el-form-item>
					</el-col>

					<el-col :span="9">
						<el-form-item :prop="'classifyList.' + index +'.acceptSuffix'" :rules="{ required: true, message: '请输入格式类型', trigger: 'blur' }">
							<el-input v-model="item.acceptSuffix" placeholder="支持多种格式，逗号分隔" maxlength="100" />
						</el-form-item>
					</el-col>

					<el-col :span="4">
						<el-button type="text" icon="el-icon-plus" @click="handleAdd(index)"></el-button>
						<el-button type="text" icon="el-icon-minus" v-if="index > 0" @click="handleRemove(index)"></el-button>
					</el-col>
				</el-row>
			</el-form-item>

			<el-form-item class="text-right">
				<el-button @click="quxiao">取消</el-button>
				<el-button type="primary" @click="handleSubmit">确定</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
	export default {
		data() {
			return {
				form: {
					id: "",
					name: "",
					classifyList: []
				},
				rules: {
					name: {
						required: true,
						message: "请输入组别名称",
						trigger: "blur"
					}
				},
				options: [{
						value: 0,
						label: "视频"
					},
					{
						value: 1,
						label: "图片"
					},{
						value: 2,
						label: "其它"
					}
				]
			};
		},

		props: {
			show: {
				type: Boolean,
				default: false
			},
			data: {}
		},

		computed: {
			isShow: {
				get() {
					return this.show;
				},
				set(value) {
					this.$emit("update:show", value);
				}
			}
		},

		mounted() {
			this.init();
		},

		methods: {
			init() {
				let temp = this;

//				console.log('data.id=',this.data.id);
				if(this.data.id){
					this.$post('/queryGroupAndChannel', {
						groupId: this.data.id
		      }).then(rep => {
		      	let tVideoGroup = rep.content.tVideoGroup;
		      	temp.form.id = tVideoGroup.id;
		      	temp.form.name = tVideoGroup.groupName;
		      	temp.form.classifyList = tVideoGroup.channelList;
		      })
				}

				if(!this.form.classifyList.length) {
					this.form.classifyList.push(this.getClassifyItem());
				}
			},

			handleSubmit() {
				let temp = this;
				this.$refs["form"].validate(valid => {
					if(valid) {
//						console.log(JSON.stringify(this.form));

						this.$post('/addGroupAndChannel', {
							form: JSON.stringify(this.form)
			      }).then(rep => {
			      	temp.$message({
								type: 'success',
								message: '添加成功！'
							});
							temp.isShow = false;
							//刷新父页面
							temp.$emit("init");
			      })
					} else {
						return false;
					}
				});
			},

			getClassifyItem() {
				const _classifyAddItem = {
					id: "",
					channelName: "",
					type: "",
					acceptSuffix: ""
				};
				return _classifyAddItem;
			},

			handleAdd(index) {
				this.form.classifyList.splice(index + 1, 0, this.getClassifyItem());
			},

			handleRemove(index) {
				this.form.classifyList.splice(index, 1);
			},
			quxiao(){
					this.isShow = false;
			}

		}
	};
</script>

<style lang="scss" scoped>

	/deep/ .el-form>.el-form-item>.el-form-item__content {
		padding: 0 5px;
	}
</style>
