<template>
	<z-plate has-bottom-border>
		<template #titleLeft>
			<el-tabs v-model="activeName" @tab-click="handleClick">
				<el-tab-pane label="评选分类维护" name="first"></el-tab-pane>
				<el-tab-pane label="评分规则维护" name="second"></el-tab-pane>
        <el-tab-pane label="强制转换视频" name="third"></el-tab-pane>
			</el-tabs>
		</template>

		<template #titleRight v-if="activeName === 'first'">
			<el-button type="primary" icon="el-icon-circle-plus-outline" @click="addDialog1 = true; data.id = ''">新增组别</el-button>
		</template>

		<template #titleRight v-else-if="activeName === 'second'">
			<el-button type="primary" icon="el-icon-circle-plus-outline" @click="addDialog2 = true; data.id = ''">新增</el-button>
		</template>

		<!-- 评选分类维护 -->
		<div class="first" v-if="activeName === 'first'" v-loading="tableLoading">
			<!-- 表格 -->
			<el-table class="z-table" :data="tableData" v-if="tableData.length" style="width: 100%">
				<el-table-column type="index" label="序号" width="60"></el-table-column>

				<el-table-column prop="groupName" label="组别"></el-table-column>

				<el-table-column prop="channelName" label="分类"></el-table-column>

				<el-table-column label="操作" width="260">
					<template slot-scope="{ row }">
						<el-button icon="el-icon-edit" @click="edit(row)" ></el-button>
						<el-button icon="el-icon-delete" @click="del(row)" ></el-button>
					</template>
				</el-table-column>
			</el-table>

			<v-nodata v-else></v-nodata>
			<!-- 分页 -->
			<!--<z-pagination v-if="pagination.total > 0" :page.sync="pagination.page" :limit.sync="pagination.limit" :total="pagination.total" @pagination="init"></z-pagination>-->
		</div>

		<!-- 评分规则维护 -->
		<div class="second" v-if="activeName === 'second'" v-loading="tableLoading">
			<!-- 表格 -->
			<el-table class="z-table" :data="tableData" v-if="tableData.length" style="width: 100%">
				<el-table-column type="index" label="序号" width="60"></el-table-column>

				<el-table-column prop="ratingName" label="评分规则"></el-table-column>

				<el-table-column prop="channelName" label="适用分类"></el-table-column>

				<el-table-column label="操作" width="260">
					<template slot-scope="{ row }">
						<el-button icon="el-icon-edit" @click="editRule(row)" ></el-button>
						<el-button icon="el-icon-delete" @click="delRule(row)" ></el-button>
					</template>
				</el-table-column>
			</el-table>

			<v-nodata v-else></v-nodata>
			<!-- 分页 -->
			<!--<z-pagination v-if="pagination.total > 0" :page.sync="pagination.page" :limit.sync="pagination.total" :total="pagination.total" @pagination="init"></z-pagination>-->
		</div>

    <!-- 强制转码视频 -->
    <div class="first" v-if="activeName === 'third'" v-loading="tableLoading">
      <el-form class="second-form" inline style="margin-left: 20px; margin-top: 30px;">
          <el-form-item>
            <el-select v-model="detailForm.activityid" placeholder="请选择活动" @change="queryChannelByActivityId" clearable collapse-tags >
              <el-option value="">请选择</el-option>
              <el-option
                v-for="item in activityList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-select v-model="detailForm.channelId" placeholder="请选择" multiple clearable collapse-tags>
              <el-option
                v-for="item in channelList"
                :key="item.channelId"
                :label="item.groupName + '-' + item.channelName"
                :value="item.channelId"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-input v-model="detailForm.videoName" placeholder="作品名称" clearable maxlength="50"/>
          </el-form-item>

          <el-form-item>
            <el-button icon="el-icon-search" @click="queryDetailList">搜索</el-button>
          </el-form-item>
        </el-form>

        <!-- 表格 -->
        <el-table class="z-table" v-if="page.content.length" :data="page.content" style="width: 100%">
          <el-table-column type="index" label="序号" width="60"></el-table-column>

          <el-table-column prop="channelName" label="作品分类"></el-table-column>

          <el-table-column prop="videoName" label="作品名称"></el-table-column>

          <el-table-column prop="schoolName" label="上报单位"></el-table-column>

          <el-table-column prop="userName" label="联系人"></el-table-column>

          <el-table-column prop="userPhone" label="电话"></el-table-column>

          <el-table-column label="操作" width="260">
          	<template slot-scope="{ row }">
          		<el-button @click="videoZhuanma(row)" >视频转码</el-button>
          	</template>
          </el-table-column>
        </el-table>

        <v-nodata v-else></v-nodata>
    </div>

		<v-add-1 v-if="addDialog1" :show.sync="addDialog1" :data="data" @init="init" ></v-add-1>
		<v-add-2 v-if="addDialog2" :show.sync="addDialog2" :data="data" @init="init" ></v-add-2>
	</z-plate>
</template>

<script>
	import VAdd1 from "./SystemAdd1";
	import VAdd2 from "./SystemAdd2";
	export default {
		components: {
			VAdd1,
			VAdd2
		},

		data() {
			return {
				activeName: "first",
				tableData: [],
				pagination: {
					page: 0, //当前页
					limit: 10, //分页条数
					total: 1 ,//总页数
          totalPages: 0 //总条数
				},
				data: {
					id: ""
				},
				form: {
					name: ""
				},
        page:{
        	content: []
        },
        detailForm: {
          activityid: '',
          channelId: '',
          videoName: '',
        },
        activityList: [],
        channelList: [],

				tableLoading: true,
				dialogVisible: false,
				addDialog1: false,
				addDialog2: false
			};
		},

		mounted() {
			this.init();
      this.queryActivityList();
		},

		methods: {
			init() {
				this.tableData = [];
				if(this.activeName === 'first'){
					this.$post('/queryGroupList', {
		      }).then(rep => {
		      	this.tableData = rep.content.list;
		      	this.tableLoading = false;
		      })
				}else{
					this.$post('/findRuleList', {
		      }).then(rep => {
		      	this.tableData = rep.content.ruleList;
		      	this.tableLoading = false;
		      })
				}
			},
			handleClick() {
				this.tableLoading = true;
				this.init();
			},
			del(row) {
//				console.log(row);
				this.$confirm("是否删除分组及分类？", "", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
			        type: "warning"
				}).then(() => {
			      	this.$post("/deleteGroupAndChannel", {
				        groupId: row.id,
				        channelId: row.channelId
					}).then(m => {
				      	if(m.resultCode == 0) {
				      		this.$message({
				      			showClose: true,
					          type: "success",
					          message: "删除成功!"
					        });
					        this.init();
				      	}
					})
				}).catch(() => {
			      	this.$message({
			          type: "info",
			          message: "已取消!",
			          showClose: true
			        });
				})
			},
			edit(row) {
//				console.log(row);
				this.data.id = row.id;
				this.addDialog1 = true;
			},
			delRule(row) {
				// console.log(row);
				this.$confirm("是否删除评分规则及评分项？", "", {
	        		confirmButtonText: "确定",
	        		cancelButtonText: "取消",
	        		type: "warning"
	      		}).then(() => {
	      			this.$post("/deleteRule", {
		        		ruleId: row.id,
		        		itemid: row.itemId
		      		}).then(m => {
		      			if(m.resultCode == 0) {
		      				this.$message({
		      					showClose: true,
			          			type: "success",
			          			message: "删除成功!"
			        		});
			        		this.init();
		      			}
		      		})
	      		}).catch(() => {
	      			this.$message({
	          			type: "info",
	          			message: "已取消!",
	          			showClose: true
	        		});
	      		})
			},
			editRule(row) {
				// console.log(row);
				this.data.id = row.id;
				this.addDialog2 = true;
			},
      //查询所有活动
      queryActivityList(){
      	this.$post('/queryActivityList', {
      		page: 0,
      		size: 100
      	}).then(rep => {
      		this.activityList = rep.content.page.content;
      	})
      },
      //根据活动id查询分类
      queryChannelByActivityId(){
      	this.$post('/queryChannelById',{"activityId": this.detailForm.activityid}).then(rep => {
      		this.channelList = rep.content.list;
      	})
      },
      queryDetailList(){
      	var channelid = '';
      	if(this.detailForm.channelId){
      		channelid = this.detailForm.channelId.join();
      	}
      	let {pagination} = this;
      	this.tableLoading = true;
      	this.$post('/queryDetailListByActivityId',{
      		activityid: this.detailForm.activityid,
      		channelId: channelid,
      		videoName: this.detailForm.videoName,
      		schoolName: this.detailForm.schoolName,
      	}).then(rep => {
      		this.$nextTick(() => {
      	   	this.page = rep.content.page;
      	    this.tableLoading = false;
      		})
      	})
      },
      videoZhuanma(row){
        // console.log("强制视频转码",row.videoId);
        this.$post('/qiangzhishipinzhuanma',{
          videoId: row.videoId
        }).then(rep => {
          this.$message({
            type: "info",
            message: "正在转码稍后查看!",
            showClose: true
          });
        })
      },
		}
	};
</script>

<style lang="scss" scoped>
	// .first {
	//   & > section {
	//     padding: 15px 20px;
	//   }
	// }
</style>
